// Disable TypeScript checking for this file until all of the imports are valid TS as well
// This will allow us to slowly migrate to TS without forcing us to address all type errors immediately
// @ts-nocheck
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store/store';
import i18n from './locales/composables/i18n';
import {VuetifyService} from '@/vuetify/services';
import moment from 'moment';
import Dialog from '@/errors/plugins/error-dialog';
import VueCompositionAPI, {createApp} from '@vue/composition-api';
import ErrorReportingService from './errors/plugins/error-reporting';
import LoadingFlagsPlugin from './loading/plugins/loading-flags';
import AuthServicePlugin from './auth/plugins/authServicePlugin';
import {useAuthService} from '@/auth/services/authService';
import ACLPlugin from './permissions/plugins/permissionsPlugin';
import AnalyticsPlugin from './telemetry/plugins/vue-analytics';
import HierarchyServicePlugin from './courses/plugins/course-hierarchy';
import AssignablesService from '@/assignments/plugins/assignables';
import VeeValidatePlugin from '@/common/plugins/vee-validate';
import {IconPlugin} from '@/icons/plugins/icons';
import {HTTP} from '@/common/api/http';
import SuccessSnackbarPlugin from '@/common/plugins/success-snackbar';
import {useVersionCheckService} from '@/versioning/services/VersionCheckService';
import {inject} from '@/container';
import {CourseHierarchyService} from '@/courses/services/CourseHierarchyServiceClass';
import {LoadingFlagsService} from '@/loading/services/LoadingFlagsService';
import VuetifyPlugin from 'vuetify/lib';
import VueMask from 'v-mask';
import '@/sass/overrides.sass';
import {placeholders} from '@/common/utils/v-mask-placeholders';
import {installDirective as installFormatInputDirective} from '@/common/directives/formatInputDirective';
import {install as HubspotPlugin} from '@/onboarding/plugins/hubspot';
import {FeatureFlagsPlugin} from '@/feature-flags/plugins/feature-flags';
import {UseCookieConsentService} from '@/cookie-consent/services/UseCookieConsentService';
import {ConsentCategory} from '@/cookie-consent/types/ConsentCategory';
import TextHighlightPlugin from '@/common/plugins/text-highlight';
import SentryReporter from '@/errors/services/error-reporting/sentry-reporter';
import {getEnvironment, isDevelopment} from '@/environment';

Vue.config.productionTip = false;
Vue.config.devtools = isDevelopment();
// FIXME: not sure if I like having moment on Vue instances. Also, would make it $moment
Vue.prototype.moment = moment;

Vue.use(VuetifyPlugin);
Vue.use(VueCompositionAPI);

// Create cookie consent service for managing installation of tracking services
const consentService = inject(UseCookieConsentService);

// Create the global app instance
const app = createApp({
  router,
  store,
  vuetify: inject(VuetifyService),
  i18n,
  render: (h) => h(App),
});

/*
 * Register plugins
 */
app.use(VueMask, {placeholders});
app.use(LoadingFlagsPlugin, {store});
app.use(HierarchyServicePlugin);
app.use(Dialog);
app.use(SuccessSnackbarPlugin);
app.use(AuthServicePlugin, {
  store,
  HTTP,
  promiseManager: inject(LoadingFlagsService),
  router,
  hierarchyService: inject(CourseHierarchyService),
});

app.use(AssignablesService, {hierarchyService: inject(CourseHierarchyService)});
app.use(ACLPlugin, {userStorage: useAuthService().userStorage});

app.use(ErrorReportingService);

app.use(VeeValidatePlugin);
app.use(IconPlugin);
app.use(FeatureFlagsPlugin);
app.use(TextHighlightPlugin);

/**
 * Consent Service
 *
 * Setup plugins only after the user has consented.
 */

// Analytics
consentService.addCallback(ConsentCategory.Analytics, () =>
  app.use(AnalyticsPlugin, {
    auth: useAuthService(),
    router,
  })
);

// Support
consentService.addCallback(ConsentCategory.Support, () =>
  app.use(HubspotPlugin, {
    auth: useAuthService(),
  })
);
consentService.addCallback(ConsentCategory.Support, () => {
  const env = getEnvironment();
  if (env.sentryDsn) {
    ErrorReportingService.getInstance()?.addErrorReporter(
      new SentryReporter(app, router, env.sentryDsn, env.mode, env.buildSha)
    );
  }
});

/*
 * Directives
 */
installFormatInputDirective(app);

const root = app.mount('#app');

/*
 * Boot services
 */
consentService.runCallbacks();
useVersionCheckService().boot();

export {app, root};
